<template>
  <div>
    <div class="checkoutboard">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-10">
            <router-view></router-view>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="scss" scoped>
  .checkoutboard{
    background-color: #F0F0F2;
  }
  .container {
    min-height: calc(100vh - 298px);
  }
</style>
